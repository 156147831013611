import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

// 3rd party components
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';

// core components
import Header from "../../components/Headers/Header.jsx";

import {get_user_details, get_all_stores} from '../../actions';
import {get_investment_status, create_investment, update_investment, delete_investment} from './actions';

import './style.css';

class Home extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          stores: null,
          current_page: 1,
          page_size: 100,
          all_pages: null,
      };
  }


  componentWillMount() {
      this.refresh_data();      
  }


  componentDidUpdate(prevProps) {
      if (prevProps.fetching_store_data !== this.props.fetching_store_data) {
          this.refresh_data();
      }
      console.log(this.props.search_text)
  }


  refresh_data = no_cache => { 
      if (this.props.fetching_store_data) {
          return Promise.resolve();
      }
      if (!no_cache && this.props.stores && this.props.stores.length) {
          var fetch_stores = Promise.resolve();
      } else {
          var fetch_stores = this.props.get_all_stores(); 
      }
      return fetch_stores
      .then(() => {
          var stores = this.props.stores;
          console.log('stores => ', stores);
          var pages = Number((stores.length / this.state.page_size).toFixed(0));
          var all_pages = Array.apply(null, {length: pages}).map(Number.call, Number)
          all_pages.splice(0, 1);
          this.setState({stores, all_pages}, () => console.log(this.state))
      })
      .catch(err => toastr.error('Something went wrong'))
  }

  update_inputs = (event) => {
      console.log(this.state);
      this.setState({[event.target.name]: event.target.value})
  }

  apply_search_filter = x => {
      if (this.props.search_text) {
          console.log('search_text found', this.props.search_text, x.name);
          if (x.name && x.name.toLowerCase().includes(this.props.search_text.toLowerCase())) {
              return true;
          } else {
              return false;
          }
      } else {
          console.log('no search_text')
          return true
      }
  }

  render() {
    const styles = {
      header_row: {
        marginLeft: 0,
        overflowX: "auto",
      },
      table_header: {
        fontWeight: "bold",
        fontSize: 'small'
      },
      round_status: {
        fontSize: 'small',
        paddingBottom: 0
      },
      nav_pills_rounds: {
        paddingRight: 60,
        marginRight: "0.7rem",
        marginLeft: "0.2rem",
      },
      action_button: {
        margin: 1       
      }
    }; 
    
    const table_pagination = (
        <nav aria-label="Page navigation example" style={{display: "inline-block"}}>
          <Pagination>
            <PaginationItem>
              <PaginationLink aria-label="Previous" onClick={() => this.setState({current_page: (this.state.current_page > 1) ? (this.state.current_page - 1) : this.state.current_page})}>
                <i className="fa fa-angle-left" /><span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>
            {this.state.all_pages && this.state.all_pages.map(page => (
                <PaginationItem className={(this.state.current_page == page ? "active" : '')}>
                    <PaginationLink onClick={() => this.setState({current_page: page})}>{page}</PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem>
              <PaginationLink aria-label="Next" onClick={() => this.setState({current_page: (this.state.current_page < this.state.all_pages.length) ? (this.state.current_page + 1) : this.state.current_page})}>
                <i className="fa fa-angle-right" /> <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
    );

    const success_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-success" />
        completed
      </Badge>
    );

    const warning_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-warning" />
        pending
      </Badge>
    );

    const correct_button = (
        <Badge color="primary" >
            <span className="inner-icon fa fa-check" />
        </Badge>
    )

    const wrong_button = (
        <Badge color="warning" >
            <span className="inner-icon fa fa-times" />
        </Badge>
    )

    const rupee_symbol = <>&#8377; </>

    const store_table = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>ID</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Name</b></span><br /></th>
            {/* <th scope="col"><span style={styles.table_header}><b>Premium</b></span> <br /></th> */}
            <th scope="col"><span style={styles.table_header}><b>Type</b></span> <br /></th>
            <th scope="col"><span style={styles.table_header}><b>Plan Type</b></span> <br /></th>
            {/* <th scope="col"><span style={styles.table_header}><b>Indian</b></span> <br /></th> */}
            <th scope="col"><span style={styles.table_header}><b>Actions</b></span><br /></th>
          </tr>
        </thead>
        <tbody >
            {this.state.stores && this.state.stores.filter(this.apply_search_filter).slice((this.state.current_page - 1) * this.state.page_size, this.state.current_page * this.state.page_size).map(store => (
                <tr>
                    <td>{store.id}</td>
                    <td>{store.name}</td>
                    {/* <td>{store.premium_customer ? correct_button : wrong_button}</td> */}
                    <td>{store.type}</td>
                    <td>{store.plan_type}</td>
                    {/* <td>{store.market == 'india' ? correct_button : wrong_button}</td> */}
                    <td><Link to={"/home/store_dashboard/" + store.id}><Button className="btn-sm" color="primary" >Manage</Button></Link></td>
                </tr>
            ))}
        </tbody>
      </Table>
    )


    const store_filler = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>Fetching all stores </b></span><br/> Please wait...<br /> &nbsp;</th>
          </tr>
        </thead>
      </Table>
    )


    return (
      <>
        <Header />
        {/* Page content */}
        
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row style={styles.header_row} className="text-center">
                      <Col xs="12" className="text-center">
                          {table_pagination}
                      </Col>
                  </Row>
                </CardHeader>
                {this.state.stores ? store_table : store_filler}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    stores: state.stores,
    user_details: state.user_details,
    fetching_store_data: state.fetching_store_data,
    search_text: state.search_text,
    ...own_props
})


const mapDispatchToProps = dispatch => ({
    get_all_stores: () =>  dispatch(get_all_stores()),
    get_user_details: () =>  dispatch(get_user_details()),
    get_investment_status: () =>  dispatch(get_investment_status()),
    create_investment: data =>  dispatch(create_investment(data)),
    update_investment: data =>  dispatch(update_investment(data)),
    delete_investment: data =>  dispatch(delete_investment(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);