import Maps from "./views/examples/Maps.jsx";
import Register from "./views/examples/Register.jsx";
import Login from "./views/examples/Login.jsx";
import Home from "./views/Home";
import Traction from "./views/Traction";
import Icons from "./views/examples/Icons.jsx";
import Meetings from "./views/Meetings";
import Documents from "./views/Documents";
import Profile from "./views/Profile";
import Working from "./views/Working";
import Stores from "./views/Stores";
import Orders from "./views/Orders";
import Billing from "./views/Billing";

var routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "home",
  //   component: Traction,
  //   layout: "/home"
  // },
  {
    path: "/stores",
    name: "Stores",
    icon: "briefcase",
    component: Stores,
    layout: "/home"
  },
  // {
  //   path: "/orders",
  //   name: "Orders",
  //   icon: "database",
  //   component: Orders,
  //   layout: "/home"
  // },
  // {
  //   path: "/billing",
  //   name: "Postpaid Billing",
  //   icon: "credit-card",
  //   component: Billing,
  //   layout: "/home"
  // },
  // {
  //   path: "/pitchdeck",
  //   name: "Pitchdeck",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Documents,
  //   layout: "/home"
  // },
  // {
  //   path: "/traction",
  //   name: "Traction",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Traction,
  //   layout: "/home"
  // },
  // {
  //   path: "/investments",
  //   name: "Investments",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/home"
  // },
  // {
  //   path: "/team_infurnia",
  //   name: "Team Infurnia",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/home"
  // },
  // {
  //   path: "/about_infurnia",
  //   name: "About Infurnia",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/home"
  // },
];
export default routes;
