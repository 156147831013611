import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import ReduxToastr from 'react-redux-toastr';

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import "./assets/css/argon-dashboard-react.css";

import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";

import './config';
import store from './store';

ReactDOM.render(
	<Provider store={store}>
	  	<BrowserRouter>
		    <Switch>
		      	<Route path="/home" render={props => <AdminLayout {...props} />} />
		      	<Route path="/login" render={props => <AuthLayout {...props} page='login' />} />
		      	<Route path="/signup" render={props => <AuthLayout {...props} page='signup' />} />
		      	<Redirect from="/" to="/home/stores" />
		    </Switch>    
	  	</BrowserRouter>
      	<ReduxToastr position="bottom-right" timeOut={2000}/>
	</Provider>,
  document.getElementById("root")
);
