import React from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// core components
import Header from "../../components/Headers/Header.jsx";
import {chartOptions, parseOptions, chartExample1, chartExample2} from "../../variables/charts.jsx";
// 3rd party components
import {FormGroup, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';

import {get_user_details, get_all_stores} from '../../actions';
import {get_license_statistics} from './actions';

// import './style.css';
const month_names = {6: 'July', 7: 'Aug', 8: 'Sep'};

class Home extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          active_chart_tab: 'weekly',
          line_chart_data: {},
          active_line_chart_data: {}
      };
  }


  switch_chart_tab = (e, active_chart_tab) => {
      e.preventDefault();
      console.log(active_chart_tab, this.state.line_chart_data)
      // var active_line_chart_data = this.state.line_chart_data[active_chart_tab]
      this.setState({active_chart_tab})
  }

  componentWillMount() {
      if (window.Chart) {
         parseOptions(Chart, chartOptions());
      }
  };

  componentDidMount() {
      this.refresh_data();
  }

  // componentDidUpdate(prevProps) {
  //     if (prevProps.fetching_store_data !== this.props.fetching_store_data) {
  //         this.refresh_data();
  //     }
  // }


  date_diff = (first, second) => Math.round((second-first)/(1000*60*60*24));

  refresh_data = async no_cache => { 
      console.log('refreshing data')
      var all_licenses = await this.props.get_license_statistics();
      var user_data = all_licenses.reduce((final, elem) => final[elem.user_id] ? {...final, [elem.user_id]: [...final[elem.user_id], elem]} : {...final, [elem.user_id]: [elem]}, {});
      console.log('refreshing data complete', user_data);

      var valid_months = [7, 8, 9, 10];
      var monthly_data = valid_months.reduce((final, month) => {
          var start_date = new Date(2019, (month - 1), 1); 
          var end_date = new Date(2019, month, 0);
          var valid_users = Object.keys(user_data).filter(x => {
              var flag = false;
              user_data[x].map(lu => {
                  var lu_end_date = lu.end_date ? new Date(lu.end_date) : null;
                  var lu_start_date = new Date(lu.start_date);
                  var tmp_1 = start_date > lu_start_date ? start_date : lu_start_date;
                  var tmp_2 = lu_end_date ? (end_date < lu_end_date ? end_date : lu_end_date) : end_date;
                  var total_days = this.date_diff(tmp_1, tmp_2);
                  if ((!lu.end_date || ( lu_end_date > end_date)) && (lu_start_date < end_date) && ((new Date(lu.License.valid_till)) > start_date) && total_days > 15) {
                      flag = true;
                  }
              })
              return flag;
          })
          return {...final, [month]: valid_users.map(x => user_data[x])};
      }, {})

      // console.log(all_licenses);
      // var monthly_data = valid_months.reduce((final, month) => {
      //     var start_date = new Date(2019, (month - 1), 1); 
      //     var end_date = new Date(2019, month, 0);
      //     var valid_licenses = all_licenses.filter(x => {
      //         var flag = false;
      //         x.license_user.map(lu => {
      //             if ((!lu.end_date || (new Date(lu.end_date) > end_date)) && (new Date(lu.start_date) < end_date)) {
      //                 console.log(month, 'in', x.license_plan.name)
      //                 flag = true;
      //             } else {
      //                 console.log(month, 'out', new Date(lu.end_date), new Date(lu.start_date), x.license_plan.name, x.valid_till, (!lu.end_date || (new Date(lu.end_date) > end_date)) && (new Date(lu.start_date) < start_date), lu, start_date, end_date)
      //             }
      //         })
      //         // console.log(start_date, end_date) 
      //         console.log(flag, new Date(x.valid_till), flag && ((new Date(x.valid_till)) > start_date))
      //         if (flag && ((new Date(x.valid_till)) > start_date)) {
      //             return true
      //         }
      //         return false;
      //     })
      //     return {...final, [month]: valid_licenses};
      // }, {})
      var monthly = {
          labels: ["July", "August", "September"],
          datasets: [
            {
              label: "Performance",
              data: Object.keys(monthly_data).map(x => monthly_data[x].length)
            }
          ]
      }
      var weeks = [new Date('2019-07-01'), new Date('2019-07-07')];
      var m = valid_months[valid_months.length - 1];
      var final_date = new Date();
      final_date.setDate(final_date.getDate() + 6 - final_date.getDay());
      // console.log(m, final_date, last_date, last_date < final_date)
      var week_labels = ['Jul 7'];
      while(true) {
          var last_date = new Date(weeks[weeks.length -1]);
          last_date.setDate(last_date.getDate() + 7);
          if (last_date < final_date) {
              weeks.push(last_date);
              week_labels.push(month_names[last_date.getMonth()] + ' ' + last_date.getDate());
          } else {
              break;
          }
      }
      // weeks.splice(-1, 1);

      var weekly_data = weeks.reduce((final, week, index) => {
          var start_date = new Date(week); 
          if (index == (weeks.length - 1)) {
              var m = valid_months[valid_months.length - 1];
              console.log(m)
              var end_date = new Date(2019, m, 0)
          } else {
              var end_date = new Date(weeks[index + 1]);
          } 

          console.log(start_date, end_date) 
          var valid_users = Object.keys(user_data).filter(x => {
              var flag = false;
              var total_days = 0;
              user_data[x].map(lu => {
                  var lu_end_date = lu.end_date ? new Date(lu.end_date) : null;
                  var lu_start_date = new Date(lu.start_date);
                  // var tmp_1 = start_date > lu_start_date ? start_date : lu_start_date;
                  // var tmp_2 = lu_end_date ? (end_date < lu_end_date ? end_date : lu_end_date) : end_date;
                  // var days = tmp_2 - tmp_1;
                  var lu_valid_till = new Date(lu.License.valid_till);
                  var eff_last_date = lu_end_date ? lu_end_date : lu_valid_till;
                  var effective_start_date = Math.max(start_date, lu_start_date);
                  var effective_end_date = Math.min(end_date, eff_last_date);
                  var eff_time = (effective_end_date - effective_start_date > 0) ? (effective_end_date - effective_start_date) : 0;
                  // if ((!lu.end_date || ( lu_end_date > end_date)) && (lu_start_date < end_date) && ((new Date(lu.License.valid_till)) > start_date)) {
                      total_days += eff_time;
                      if (lu.user_id == 2784) {
                        console.log('iteration --> ', total_days, eff_time, effective_start_date, effective_end_date, index, lu)
                      } 
                  // }
              })
              if (user_data[x][0].user_id == 2784) {
                  console.log('total ---> ', total_days)
              }
              total_days = Math.round((total_days)/(1000*60*60*24))
              if (total_days > 3) {
                  flag = true;
              } else {
                  flag = false;
              }
              return flag;
          })
          return {...final, [index]: valid_users.map(x => user_data[x])};
      }, {})

      var weekly = {
          // labels: weeks.map((x, index) => ('Week ' + index + '(' + month_names[x.getMonth()] + ')')),
          labels: week_labels,
          datasets: [
            {
              label: "Performance",
              data: Object.keys(weekly_data).map(x => weekly_data[x].length)
            }
          ]
      }
      // var weekly={}
      // var weekly_data={}
      var line_chart_data = {weekly: canvas => JSON.parse(JSON.stringify(weekly)), monthly: canvas => JSON.parse(JSON.stringify(monthly))};
      console.log('final data', monthly_data, weekly_data, 'sadasdasdassdasd', weekly, monthly);
      // var active_line_chart_data = JSON.parse(JSON.stringify(line_chart_data[this.state.active_chart_tab]))
      this.setState({line_chart_data});
  }


  render() {
    const styles = {
      header_row: {
        marginLeft: 0,
        overflowX: "auto",
      },
      table_header: {
        fontWeight: "bold",
        fontSize: 'small'
      },
      round_status: {
        fontSize: 'small',
        paddingBottom: 0
      },
      nav_pills_rounds: {
        paddingRight: 60,
        marginRight: "0.7rem",
        marginLeft: "0.2rem",
      },
      action_button: {
        margin: 1       
      }
    }; 

    return (
      <div>
        <Header />
        {/* Page content */}
        
        <Container className="mt--8" fluid>
          {/* Table */}

          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6>
                      <h2 className="text-white mb-0">Licensed User Count</h2>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink className={classnames("py-2 px-3", {active: this.state.active_chart_tab === 'monthly'})} href="#" onClick={e => this.switch_chart_tab(e, 'monthly')}>
                            <span className="d-none d-md-block">Monthly</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames("py-2 px-3", {active: this.state.active_chart_tab === 'weekly'})} data-toggle="tab" href="#" onClick={e => this.switch_chart_tab(e, 'weekly')}>
                            <span className="d-none d-md-block">Weekly</span>
                            <span className="d-md-none">W</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line data={this.state.line_chart_data[this.state.active_chart_tab]} options={chartExample1.options} getDatasetAtEvent={e => console.log(e)}/>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    stores: state.stores,
    user_details: state.user_details,
    ...own_props
})


const mapDispatchToProps = dispatch => ({
    get_all_stores: () =>  dispatch(get_all_stores()),
    get_user_details: () =>  dispatch(get_user_details()),
    get_license_statistics: () =>  dispatch(get_license_statistics()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);