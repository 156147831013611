import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import general_fetch from '../../utils/fetch';

// 3rd party components
import {FormGroup, Button, Form, Input, Modal, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardHeader, CardFooter, Col, Table, Row, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';
import Moment from 'react-moment';
import ReactDatetime from "react-datetime";

// core components
import Header from "../../components/Headers/Header.jsx";

import {get_user_details} from '../../actions';
import {get_all_orders, get_investment_status, create_investment, update_investment, delete_investment} from './actions';

import {CSVLink} from "react-csv";

import './style.css';
import '../../index.css'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const UserDetailsModal = ({open, handleClose, user_info}) => {

  useEffect(() => {
    console.log('user_info', user_info);
  }, [user_info]);

  return(
    <Modal className='modal-dialog-centered' isOpen = {open} toggle = {handleClose}>
        <CardBody style={{maxHeight:'500px', position: 'relative'}}>
            <i className="fa fa-times" onClick={handleClose} style={{position:'absolute', top:'8px', right:'8px', cursor:'pointer', fontSize:'18px'}}/>
            <div style={{margin:'12px 0px', fontWeight:'900'}}>User Details : </div>
            <div style={{fontSize:'14px'}}>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight:'600'}}>Email :</span> {user_info ? user_info.email : ''}</div>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight:'600'}}>Roles :</span> {user_info && user_info.role_usage && user_info.role_usage.length ? user_info.role_usage.map((x,idx) => {
                if(idx == 0){
                  return x.role_id
                }else{
                  return String(', '+x.role_id)
                }
              }) : ''}</div>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight:'600'}}>Usage Start Date:</span> {user_info && user_info.usage_start_date ? new Date(user_info.usage_start_date).toString().substring(4, 15) : ''}</div>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight:'600'}}>Usage End Date:</span> {user_info && user_info.usage_end_date ? new Date(user_info.usage_end_date).toString().substring(4, 15) : ''}</div>
            </div>
            <div style={{margin:'16px 0px', fontWeight:'900'}}>Render Count : </div>
            <div style={{fontSize:'14px'}}>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight:'600'}}>HD :</span> {user_info && user_info.render_usage && user_info.render_usage.render ? user_info.render_usage.render['HD'] : '0'}</div>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight:'600'}}>Full HD :</span> {user_info && user_info.render_usage && user_info.render_usage.render ? user_info.render_usage.render['Full_HD'] : '0'}</div>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight:'600'}}>4K :</span> {user_info && user_info.render_usage && user_info.render_usage.render ? user_info.render_usage.render['4K'] : '0'}</div>
            </div>
        </CardBody>
    </Modal>
  )
}

const FinancialStatement = ({open, handleClose, bill_id, set_page_loader, account_transactions, start_date, end_date}) => {
  
    const [financial_statement, set_financial_statement] = useState();

    const fetch_financial_statement = async(bill_id) => {
      try{
          set_page_loader(true, 'fetching financial statement...')
          let resp = await general_fetch({url:"billing/get_financial_statement", body:{bill_id:bill_id}})
          set_page_loader(false)
          console.log('financial statement', resp)
          set_financial_statement(resp)
      }catch(err){
        console.log(err)
      }
    }

    useEffect(() => {
      if(open && bill_id){
        fetch_financial_statement(bill_id)
      }
      else if(open && account_transactions){
        let x = {}
        x.account_transactions = account_transactions;
        x.start_date = start_date;
        x.end_date = end_date;
        set_financial_statement(x);
      }
    }, [open, bill_id]);

    return(
      <Modal className='modal-dialog-centered' size = 'xl' isOpen = {open} toggle = {handleClose}>
        <CardHeader className='flex_property' style={{fontWeight:'900', fontSize:'18px', height:'50px'}}>Financial Statement ({new Date(financial_statement ? financial_statement.start_date : '').toString().substring(4, 15) + ' - ' + new Date(financial_statement ? financial_statement.end_date : '').toString().substring(4, 15)})<i className="fa fa-times" onClick={handleClose} style={{position:'absolute', top:'12px', right:'12px', cursor:'pointer', fontSize:'18px'}}/></CardHeader>
          <CardBody style={{maxHeight:'500px', position: 'relative', overflow:'auto'}}>
              
              <div style={{marginBottom:'12px', color:'black', fontWeight:'900'}}>Account Transactions</div>
              {
                financial_statement && financial_statement.account_transactions && financial_statement.account_transactions.length ?
                <table style={{width:'100%'}}>
                <thead style={{width:'100%'}}>
                  <tr className="flex_property" style={{width:'100%'}}>
                    <th className="th_style flex_property" style={{flex:'2'}}>Order ID</th>
                    <th className="th_style flex_property" style={{flex:'2'}}>Comment</th>
                    <th className="th_style flex_property" style={{flex:'1'}}>Prev Balance</th>
                    <th className="th_style flex_property" style={{flex:'1'}}>Credit</th>
                    <th className="th_style flex_property" style={{flex:'1'}}>Debit</th>
                    <th className="th_style flex_property" style={{flex:'1'}}>Acc. Balance</th>
                  </tr>
                </thead>
                <tbody style={{width:'100%'}}>
                  {
                    financial_statement.account_transactions.map(single_transaction => (
                          <tr className="flex_property display_onhover" style={{width:'100%'}}>
                            <td className="td_style flex_property" style={{flex:'2'}}>{single_transaction.id ? single_transaction.id : '-'}</td>
                            <td className="td_style flex_property" style={{flex:'2'}}>{single_transaction.order ? single_transaction.order.comment : ''}</td>
                            <td className="td_style flex_property" style={{flex:'1'}}>{single_transaction.prev_balance ? single_transaction.prev_balance.toFixed(3) : 'N/A'}</td>
                            <td className="td_style flex_property" style={{flex:'1'}}>{single_transaction.credited_amount ? single_transaction.credited_amount.toFixed(3) : 'N/A'}</td>
                            <td className="td_style flex_property" style={{flex:'1'}}>{single_transaction.debited_amount ? single_transaction.debited_amount.toFixed(3) : 'N/A'}</td>
                            <td className="td_style flex_property" style={{flex:'1'}}>{single_transaction.account_balance ? single_transaction.account_balance.toFixed(3) : 'N/A'}</td>
                          </tr>
                      ))
                  }
                </tbody>
                </table>:<div style={{fontSize:'14px'}}>No Account Transactions found</div>
              }
                {
                  financial_statement && financial_statement.final_account_transaction ? 
                  <div style={{width:'100%'}}>
                    <div style={{margin:'16px 0px', color:'black', fontWeight:"900"}}>Final Account Transaction</div>
                    {/* <thead style={{width:'100%'}}> */}
                      <tr className="flex_property" style={{width:'100%'}}>
                        <th className="th_style flex_property" style={{flex:'1'}}>Order ID</th>
                        {/* <th className="th_style flex_property" style={{flex:'2'}}>Comment</th> */}
                        <th className="th_style flex_property" style={{flex:'1'}}>Prev Balance</th>
                        <th className="th_style flex_property" style={{flex:'1'}}>Credit</th>
                        <th className="th_style flex_property" style={{flex:'1'}}>Debit</th>
                        <th className="th_style flex_property" style={{flex:'1'}}>Acc. Balance</th>
                      </tr>
                    {/* </thead> */}
                    <tr className="flex_property display_onhover" style={{width:'100%'}}>
                      <td className="td_style flex_property" style={{flex:'1'}}>{financial_statement.final_account_transaction.order_id ? financial_statement.final_account_transaction.order_id : 'Null'}</td>
                      {/* <td className="td_style flex_property" style={{flex:'2'}}>{financial_statement.final_account_transaction.order ? financial_statement.final_account_transaction.order.comment : 'Final Transaction'}</td> */}
                      <td className="td_style flex_property" style={{flex:'1'}}>{financial_statement.final_account_transaction.prev_balance.toFixed(3)}</td>
                      <td className="td_style flex_property" style={{flex:'1'}}>{financial_statement.final_account_transaction.credited_amount ? financial_statement.final_account_transaction.credited_amount.toFixed(3) : 'N/A'}</td>
                      <td className="td_style flex_property" style={{flex:'1'}}>{financial_statement.final_account_transaction.debited_amount ? financial_statement.final_account_transaction.debited_amount.toFixed(3) : 'N/A'}</td>
                      <td className="td_style flex_property" style={{flex:'1'}}>{financial_statement.final_account_transaction.account_balance.toFixed(3)}</td>
                    </tr>
                  </div>:''
                }
          </CardBody>
      </Modal>
    )
}

class Home extends React.Component {

  // let store_info = {}
  
  constructor(props) {
      super(props);
      var now = new Date();
      this.state = {
        store_id:this.props.store_info ? this.props.store_info.id : '',
        store_info: this.props.store_info,
        store_usage: {},
        selected_month_usage: {},
        year: new Date().getFullYear(),
        market: this.props.market,
        selected_date: months[now.getMonth()] + ', ' + now.getFullYear(),
        user_info : '',
        show_user_info_modal : false,
        show_financial_statement: false,
        bill_id: '',
        anchor_date: this.props.store_info ? new Date(this.props.store_info.billing_anchor_date).toString().substring(4,15) : '',
        usage_csv_data: []
      };
  }

  // componentWillReceiveProps(next_props) {
  //     this.setState({usage: next_props.usage, market: next_props.market});  
  // }

  // componenetDidUpdate(prev_props) {
  //     console.log('pp', this.props.orders);
  //     if (prev_props.orders !== this.props.orders) {
  //         this.setState({orders: this.props.orders});
  //     }
  // }

  fetch_store_usage = async() => {
    console.log('body:{store_id_to_find_unbilled_statement:this.state.store_id}', {store_id_to_find_unbilled_statement:this.state.store_id})
    let resp = ''
    if(this.props.type === 'billing_history'){
      this.props.set_page_loader(true, 'fetching billing history...')
      resp = await general_fetch({url:'billing/get_bills', body:{customer_store_id: this.state.store_id, year:this.state.selected_date.slice(-4)}})
      this.props.set_page_loader(false)
      let t = this.state.selected_date
      if(resp){
        let temp_month_usage = resp.find(o => {console.log('temp_month_usage 0', t,  months[new Date(o.end_date).getMonth()], t.substring(0, t.length-6)) ;return months[new Date(o.end_date).getMonth()] === t.substring(0, t.length-6)})
        this.setState({selected_month_usage: temp_month_usage, bill_id : temp_month_usage ? temp_month_usage.id : ''})
        this.fetch_csv_data(temp_month_usage)
      }
    }else{
      this.props.set_page_loader(true, 'fetching unbilled usage...')
      resp = await general_fetch({url:'billing/find_unbilled_statement', body:{customer_store_id:this.state.store_id}})
      this.props.set_page_loader(false)
      this.fetch_csv_data(resp)
    }
    this.setState({store_usage: resp});
    console.log('store usage', resp)
  }

  fetch_csv_data = (usage) => {
    let temp_csv_data = []
		temp_csv_data.push(['Billing Details'])
    temp_csv_data.push(['Organization name', this.props.store_info ? this.props.store_info.name : ''])
    temp_csv_data.push(['Billing Period', (new Date(usage && usage.start_date ? usage.start_date : '').toString().substring(4, 15)) + ' - ' + (new Date(usage && usage.end_date ? usage.end_date : '').toString().substring(4, 15))])
    temp_csv_data.push(['Platform Cost (in credits)', usage && usage.platform_credits ? usage.platform_credits : 0])
    temp_csv_data.push(['Extra Usage Cost (in credits)', usage && usage.designer_extra_credits ? usage.designer_extra_credits : 0])
    temp_csv_data.push(['Render Cost (in credits)', usage && usage.render_credits ? usage.render_credits : 0])
    temp_csv_data.push(['Total Cost (in credits)', usage && usage.amount ? usage.amount : 0])
    temp_csv_data.push([])
		temp_csv_data.push(['S.No', "User Id", "Email", 'Roles', "Created Date", "Is Deprecated", "Deprecated Date", "Usage Start Date", 'Usage End Date', 'HD Renders', 'Full HD Renders', '4K Renders', 'Credits Used', "Platform User", "Internal User"])

    let t = usage && usage.user_credits_summary && usage.user_credits_summary.length ? usage.user_credits_summary.map((user_usage, idx) => {
      let user_roles_str = ''
      let t = user_usage.role_usage && user_usage.role_usage.length ? user_usage.role_usage.map((role_usage, id) => {if(id > 0) user_roles_str += ', ' ;user_roles_str += role_usage.role_id}):''
      temp_csv_data.push([idx+1,
        user_usage.user_id,
        user_usage.original_email,
        user_roles_str,
        user_usage.user_created_at ? user_usage.user_created_at.substring(0, 10).split('-').reverse().join('/') : '', 
        user_usage.deprecated, 
        user_usage.deprecated && user_usage.user_deprecated_at  ? user_usage.user_deprecated_at.substring(0, 10).split('-').reverse().join('/') : '', 
        user_usage.usage_start_date ? user_usage.usage_start_date.substring(0, 10).split('-').reverse().join('/') : '', 
        user_usage.usage_end_date ? user_usage.usage_end_date.substring(0, 10).split('-').reverse().join('/') : '', 
        user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render.HD ? user_usage.render_usage.render.HD : 0, 
        user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render.Full_HD ? user_usage.render_usage.render.Full_HD : 0, 
        user_usage.render_usage && user_usage.render_usage.render && user_usage.render_usage.render['4K'] ? user_usage.render_usage.render['4K'] : 0, 
        user_usage.role_credits_used, 
        user_usage.platform ? "True" : "False", 
        user_usage.internal ? "True" : "False"])
    })
    : ''
    
    this.setState({usage_csv_data: temp_csv_data})
    
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.store_id != this.props.store_id || prevProps.type != this.props.type){
      this.fetch_store_usage()
    }

    // if(prevProps.store_id != this.props.store_id || prevProps.type != this.props.type || prevState.selected_date != this.state.selected_date){
    //   this.fetch_store_usage()
    // }

    if((prevState.selected_date.substring(0, prevState.selected_date.length-6) != this.state.selected_date.substring(0, this.state.selected_date.length-6) && prevState.selected_date.slice(-4) === this.state.selected_date.slice(-4) && this.props.type === 'billing_history' ) && this.state.store_usage && this.state.store_usage.length){
      console.log('temp_month_usage')
      console.log('Selected Date', this.state.selected_date)
      let t = this.state.selected_date
      let temp_month_usage = this.state.store_usage.find(o => {console.log('temp_month_usage 1', t,  months[new Date(o.end_date).getMonth()], t.substring(0, t.length-6)) ;return months[new Date(o.end_date).getMonth()] === t.substring(0, t.length-6)})
      console.log('temp_month_usage', temp_month_usage, this.state.store_usage, this.state.selected_date)
      this.setState({selected_month_usage: temp_month_usage, bill_id : temp_month_usage ? temp_month_usage.id : ''})
      this.fetch_csv_data(temp_month_usage)
    } 

    if(prevState.selected_date.slice(-4) != this.state.selected_date.slice(-4)){
      this.fetch_store_usage()
    }
}

  componentWillMount = () => {
    console.log("component updated", this.state.store_id);
    this.fetch_store_usage()
  }

  get_store_info = async() => {
    if(this.state.store_id){
      try{
        let resp = await general_fetch({url:'billing/get_store_info', body:{customer_store_id: this.state.store_id}})
        this.setState({store_info: resp})
      }catch(err){
        console.log(err)
      }
    }
  }

  set_anchor_date = async(date) => {
    if(this.state.store_id){
      try{
        // let resp = await general_fetch({url:'billing/set_anchor_date', body:{customer_store_id: this.state.store_id, anchor_date: date}})
        // this.get_store_info()
        this.setState({anchor_date: date})
      }catch(err){
        console.log(err)
      }
    }
  }
  
  recalculate_bills = async() => {
    if(this.state.store_id){
      try{
        this.props.set_page_loader(true, 'Recalculating bills...')
        try {
          let resp = await general_fetch({url:'billing/recalculate_bills', body:{customer_store_id: this.state.store_id, anchor_date: this.state.anchor_date}})
        } catch (err) {
          console.error(err)
          toastr.error('Something went wrong')
        }
        this.props.set_page_loader(false)
        toastr.success('Recalculate Bills Successfull')
        this.fetch_store_usage()
        if(this.props.fetch_store_info){
          await this.props.fetch_store_info()
          this.setState({anchor_date: new Date(this.props.store_info.billing_anchor_date).toString().substring(4,15)})
        }
      }catch(err){
        console.log(err)
      }
    }
  }

  reset_anchor_date = async() => {
    if(this.state.store_id){
      try{
        this.props.set_page_loader(true, 'Anchor Date being Reset')
        try {
          let resp = await general_fetch({url:'billing/reset_anchor_date', body:{customer_store_id: this.state.store_id, anchor_date: this.state.anchor_date}})
        } catch (err) {
          console.log(err)
          toastr.error('Something went wrong')
        }
        this.props.set_page_loader(false)
        toastr.success('Anchor Date Reset Successfull')

        this.fetch_store_usage()
        if(this.props.fetch_store_info){
          await this.props.fetch_store_info()
          this.setState({anchor_date: new Date(this.props.store_info.billing_anchor_date).toString().substring(4,15)})
        }
      }catch(err){
        console.log(err)
      }
    }
  }

  // is_anchor_date_valid = () => {
  //   if(this.state.store_id){
  //     let now = new Date().toString().substring(4,15);
  //     let store_creation = new Date(this.state.store_info.created_at).toString().substring(4,15)
  //     return anchor_date <= now && anchor_date > 
  //   }
  // }


  render() {
    const styles = {
      header_row: {
        marginLeft: 0,
      },
      table_header: {
        fontWeight: "bold",
        fontSize: 'small'
      },
      round_status: {
        fontSize: 'small',
        paddingBottom: 0
      },
      nav_pills_rounds: {
        paddingRight: 60,
        marginRight: "0.7rem",
        marginLeft: "0.2rem",
      },
      action_button: {
        margin: 1       
      }
    }; 
    
    const success_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-success" />
        assigned
      </Badge>
    );

    const warning_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-warning" />
        expired
      </Badge>
    );

    const rupee_symbol = <>&#8377; </>

    const currency_symbol = (currency) => {
      if(currency == 'INR'){
        return <>&#8377; </>
      }else if(currency == 'USD'){
        return <>&#36;</>
      }

    }

    const summary_table = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>Type</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Credit Cost</b></span><br /></th>
            {
              this.props.type != 'billing_history'?<th scope="col"><span style={styles.table_header}><b>{this.state.store_usage && this.state.store_usage.estimate_charge && this.state.store_usage.estimate_charge.currency ? this.state.store_usage.estimate_charge.currency : 'INR'}</b></span> <br /></th>:''
            }
          </tr>
        </thead>
        {console.log('this.props.type', this.props.type)}
        {
          this.props.type === "billing_history"?
            <tbody>
            {
              this.state.selected_month_usage?
              <>
                  <tr>
                    <th scope="col"><span style={styles.table_header}><b>Platform Cost</b></span><br /></th>
                    <td>{this.state.selected_month_usage.platform_credits}</td>
                    {/* <td>{rupee_symbol}{this.state.selected_month_usage.platform_credits}</td> */}
                  </tr>
                  <tr>
                    <th scope="col"><span style={styles.table_header}><b>Extra Usage Cost</b></span><br /></th>
                    <td>{this.state.selected_month_usage.designer_extra_credits}</td>
                    {/* <td>{rupee_symbol}{this.state.selected_month_usage.designer_extra_credits}</td> */}
                  </tr>
                  <tr>
                    <th scope="col"><span style={styles.table_header}><b>Render Cost</b></span><br /></th>
                    <td>{this.state.selected_month_usage.render_credits}</td>
                    {/* <td>{rupee_symbol}{this.state.selected_month_usage.render_credits}</td> */}
                  </tr>
                  <tr>
                    <th scope="col"><span style={styles.table_header}><b>Total Cost</b></span><br /></th>
                    <td style={{display:'flex', justifyContent:'center'}}>{this.state.selected_month_usage.amount}</td>
                    {/* <td>{rupee_symbol}{this.state.selected_month_usage.amount}</td> */}
                  </tr>
              </>:''
            }
          </tbody>:
          <tbody>
          {
            this.state.store_usage && this.state.store_usage.estimate_charge?
            <>
                <tr>
                  <th scope="col"><span style={styles.table_header}><b>Platform Cost</b></span><br /></th>
                  <td>{this.state.store_usage.estimate_charge.platform_fee_credit_cost}</td>
                  <td>{currency_symbol(this.state.store_usage.estimate_charge.currency)}{this.state.store_usage.estimate_charge.platform_fee_currency_cost}</td>
                </tr>
                <tr>
                  <th scope="col"><span style={styles.table_header}><b>Extra Usage Cost</b></span><br /></th>
                  <td>{this.state.store_usage.estimate_charge.extra_usage_credit_cost}</td>
                  <td>{currency_symbol(this.state.store_usage.estimate_charge.currency)}{this.state.store_usage.estimate_charge.extra_usage_currency_cost}</td>
                </tr>
                <tr>
                  <th scope="col"><span style={styles.table_header}><b>Render Cost</b></span><br /></th>
                  <td>{this.state.store_usage.estimate_charge.total_render_credit_cost}</td>
                  <td>{currency_symbol(this.state.store_usage.estimate_charge.currency)}{this.state.store_usage.estimate_charge.total_render_currency_cost}</td>
                </tr>
                <tr>
                  <th scope="col"><span style={styles.table_header}><b>Total Cost</b></span><br /></th>
                  <td>{this.state.store_usage.estimate_charge.total_credit_cost}</td>
                  <td>{currency_symbol(this.state.store_usage.estimate_charge.currency)}{this.state.store_usage.estimate_charge.total_currency_cost}</td>
                </tr>
            </>:''
          }
          </tbody>
        }
      </Table>
    )

    const usage_table = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>Email</b></span><br /></th>
            {/* <th scope="col"><span style={styles.table_header}><b>Status</b></span> <br /></th> */}
            <th scope="col"><span style={styles.table_header}><b>Start Date</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>End Date</b></span><br /></th>
            <th scope="col"><span style={styles.table_header}><b>Credits Used</b></span><br /></th>
            {this.props.type != "billing_history" ? <th scope="col"><span style={styles.table_header}><b>Amount</b></span><br /></th> :<th scope="col"></th>}
            {this.props.type != "billing_history" ? <th scope="col"><span style={styles.table_header}><b>Internal</b></span><br /></th> :''}
          </tr>
        </thead>
        <tbody>
        {
          this.props.type === 'billing_history'?
          <>
            {this.state.selected_month_usage && this.state.selected_month_usage.user_credits_summary && this.state.selected_month_usage.user_credits_summary.length ? this.state.selected_month_usage.user_credits_summary.map(user_usage => (
                <tr className="display_onhover">
                    <td>{user_usage.email}</td>
                    {/* <td>{user_usage.active ? 'Active' : 'Inactive'}</td> */}
                    <td><Moment format="DD/MM/YYYY">{user_usage.usage_start_date}</Moment></td>
                    <td><Moment format="DD/MM/YYYY">{user_usage.usage_end_date}</Moment></td>
                    <td>{user_usage.role_credits_used}</td>
                    <td style={{padding:'12px'}}><i className="fa fa-info invisible1" onClick={() => {this.setState({show_user_info_modal : true, user_info : user_usage})}} style={{fontSize:'18px', cursor:'pointer'}}/></td>
                </tr>
            )):''}
          </>:
          <>
            {this.state.store_usage && this.state.store_usage.user_credits_summary && this.state.store_usage.user_credits_summary.length ? this.state.store_usage.user_credits_summary.map(user_usage => (
                <tr>
                    <td>{user_usage.email}</td>
                    {/* <td>{user_usage.active ? 'Active' : 'Inactive'}</td> */}
                    <td><Moment format="DD/MM/YYYY">{user_usage.usage_start_date}</Moment></td>
                    <td><Moment format="DD/MM/YYYY">{user_usage.usage_end_date}</Moment></td>
                    <td>{user_usage.role_credits_used}</td>
                    <td>{user_usage.extra_usage_INR_cost}</td>
                    <td>{user_usage.internal ? "True" : "False"}</td>
                </tr>
            )):''}
          </>
        }
        </tbody>
      </Table>
    )


    const filler = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>No usage was reported for this month </b></span><br/> Please select another month...<br /> &nbsp;</th>
          </tr>
        </thead>
      </Table>
    )


    return (
      <>
          {/* Table */}
          <UserDetailsModal open={this.state.show_user_info_modal} handleClose={() => this.setState({show_user_info_modal:false})} user_info = {this.state.user_info}/>
          <FinancialStatement open={this.state.show_financial_statement} handleClose={() => this.setState({show_financial_statement:false})} bill_id={this.props.type==='billing_history' ? this.state.bill_id : ''} set_page_loader = {this.props.set_page_loader} start_date={this.props.type==='billing_history' ? '' : this.state.store_usage.next_bill_start_date} end_date={this.props.type==='billing_history' ? '' : this.state.store_usage.next_bill_end_date} account_transactions={this.props.type==='billing_history' ? '' : this.state.store_usage.account_transactions}/>
          <Row>
            <div className="col">
              {this.props.type === 'billing_history'?
                <div style={{marginBottom:'20px', display:'flex', justifyContent:'space-between', alignItems:'center', flexWrap:'wrap', gap:'20px'}}>
                  <div style={{padding:0}} className="mt--2 mb--2">
                      <InputGroup className="input-group-alternative" style={{border:'1px solid #5E72E4', width:'250px'}}>
                        <InputGroupAddon addonType="prepend" >
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" style={{color:'#5E72E4'}} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime style={{cursor:'pointer'}} closeOnSelect onChange={d => {this.setState({selected_date: months[d.month()] + ', ' + d.year()})}} defaultValue={this.state.selected_date} inputProps={{placeholder: "Month Wise Usage"}} dateFormat="YYYY-MM" timeFormat={false}/>
                      </InputGroup>
                  </div>
                  <div className="flex_property">
                    <div style={{fontSize:'14px'}}>Change Anchor Date here - {'\xa0'}</div>
                    {/* <InputGroup className="input-group-alternative"> */}
                      {/* <InputGroupAddon addonType="prepend" >
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" style={{color:'#5E72E4'}} />
                        </InputGroupText>
                      </InputGroupAddon> */}
                      <ReactDatetime className="rdt1" style={{cursor:'pointer', width:'220px'}} closeOnSelect onChange={d => {this.set_anchor_date(d.year()+'-'+(d.month()+1)+'-'+d.date());console.log("selected ate", d.year()+'-'+(d.month() <= 9?('0'+d.month()):(d.month()))+'-'+(d.date() <= 9?('0'+d.date()):(d.date())))}} value={new Date(this.state.anchor_date).toString().substring(4,15)} inputProps={{placeholder: "Month Wise Usage"}} dateFormat="YYYY-MM-DD" timeFormat={false}/>
                    {/* </InputGroup> */}
                    <div onClick={() => {this.recalculate_bills()}} style={{padding:'8px', display:'flex', justifyContent:'center', border:'1px solid #5E72E4', borderRadius:'8px', color:'#5E72E4', cursor:'pointer', fontSize:'14px', width:'128px', marginLeft:'10px'}}>Recalculate Bills</div>
                    <div onClick={() => {this.reset_anchor_date()}} style={{padding:'8px', display:'flex', justifyContent:'center', border:'1px solid #5E72E4', borderRadius:'8px', color:'#5E72E4', cursor:'pointer', fontSize:'14px', width:'auto', marginLeft:'10px'}}>Reset Anchor Date</div>
                  </div> 
                </div>:""
              }      
              <Card className="shadow bg-secondary">
                <CardHeader className="border-0">
                  <Row style={styles.header_row}>
                    <Col xs="9" style={{paddingLeft: 0, display:'flex', alignItems:'center'}}>
                        <b>{this.props.type==='billing_history'?'Monthly Bill':'Estimated Cost'}</b>
                        <span> 
                          {
                            this.props.type === 'billing_history' ?  
                            <>
                            {
                              this.state.selected_month_usage?
                                <div style={{display:'flex', color:'gray', fontWeight:'600'}}>
                                  {'\xa0'}
                                  (<div >{new Date(this.state.selected_month_usage.start_date).toString().substring(4, 15)}</div>
                                  {'\xa0'}-{'\xa0'} 
                                  <div >{new Date(this.state.selected_month_usage.end_date).toString().substring(4, 15)}</div>)
                                </div>:''
                            }
                            </>:
                            <>
                            {
                              this.state.store_usage ? 
                                <div style={{display:'flex'}}>
                                  {'\xa0'}
                                  (<div >{new Date(this.state.store_usage.next_bill_start_date).toString().substring(4, 15)}</div>
                                  {'\xa0'}-{'\xa0'} 
                                  <div >{new Date(this.state.store_usage.unbilled_till).toString().substring(4, 15)}</div>)
                                </div>:''
                            }
                            </>
                          }   
                        </span>
                    </Col>
                    {
                      this.props.type==='billing_history'?
                      <Col xs="3" style={{paddingLeft:0, display:'flex', justifyContent:'flex-end'}}>
                        <div onClick={() => {this.setState({bill_id : this.state.selected_month_usage.id, show_financial_statement: true})}} style={{padding:'8px', display:'flex', justifyContent:'center', border:'1px solid #5E72E4', borderRadius:'8px', color:'#5E72E4', cursor:'pointer', fontSize:'14px', width:'190px'}}>View Financial Statement</div>
                      </Col> :
                      // : this.props.type==='billing_history' ?
                      <Col xs="3" style={{paddingLeft:0, display:'flex', justifyContent:'flex-end'}}>
                        <div onClick={() => {this.setState({show_financial_statement: true})}} style={{padding:'8px', display:'flex', justifyContent:'center', border:'1px solid #5E72E4', borderRadius:'8px', color:'#5E72E4', cursor:'pointer', fontSize:'14px', width:'190px'}}>View Financial Statement</div>
                      </Col>
                      // :''
                    }
                  </Row>
                </CardHeader>
                {summary_table}
                <CardHeader className="border-0">
                  <Row style={styles.header_row}>
                    <Col xs="9" style={{paddingLeft: 0}}>
                        <b>{this.props.type==='billing_history'?'Monthly Usage':'Unbilled Usage'}</b>
                    </Col>
                    <Col xs="3" style={{paddingLeft:0, display:'flex', justifyContent:'flex-end'}}>
					              <CSVLink data = {this.state.usage_csv_data} filename={"Usage_CSV"} style={{alignItems:'center'}} >
                          <div style={{padding:'8px', display:'flex', justifyContent:'center', border:'1px solid #5E72E4', borderRadius:'8px', color:'#5E72E4', cursor:'pointer', fontSize:'14px', width:'190px'}}>Download Usage CSV</div>
                        </CSVLink>
                      </Col>
                  </Row>
                </CardHeader>
                  { usage_table }           
              </Card>
            </div>
          </Row>
      </>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    user_details: state.user_details,
    ...own_props
})


const mapDispatchToProps = dispatch => ({
    get_all_orders: () =>  dispatch(get_all_orders()),
    get_user_details: () =>  dispatch(get_user_details()),
    get_investment_status: () =>  dispatch(get_investment_status()),
    create_investment: data =>  dispatch(create_investment(data)),
    update_investment: data =>  dispatch(update_investment(data)),
    delete_investment: data =>  dispatch(delete_investment(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);