var config_settings = {
	"prod":{
		server_path: 'https://productionbackend.infurnia.com/',
		auth_redirect_path: 'https://login.infurnia.com/?target=sales',
		static_file_server_path: 'https://productionbackend.infurnia.com/',
	},
	"preprod":{
		server_path: 'https://preprodbackend.infurnia.com/',
		auth_redirect_path: 'https://preprodlogin.infurnia.com/?target=sales',
		static_file_server_path: 'https://preprodprodbackend.infurnia.com/',
	},
	"stage":{
		server_path: 'https://stage3backend.infurnia.com/',
		auth_redirect_path: 'https://stage3login.infurnia.com/?target=sales',
		// static_file_server_path: 'https://stagebackend.infurnia.com/',
		// auth_redirect_path: 'https://stagedesign.infurnia.com/login/admin',
		// website_url: 'https://stagedesign.infurnia.com/',
	},
	"dev":{
		server_path: 'https://devbackendrunning.infurnia.com/',
		auth_redirect_path: 'https://stagelogin.infurnia.com/?target=sales',
	}
}

var config = config_settings[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV :'dev']
global.config = config
