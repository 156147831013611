import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';

// reactstrap components
import { Container } from "reactstrap";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import StoreDashboard from "../views/StoreDashboard";
import Sidebar from "../components/Sidebar";
import routes from "../routes.js";
import {get_user_details, get_investor_details, get_all_license_plans, get_all_stores, get_usage} from '../actions';
import general_fetch from "../utils/fetch.js";


class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_authenticating: true,
            page_loader: {  
                            show: false,
                            text: "Please wait ..."
                         }
        }
    }

    componentWillMount() {
        this.props.get_user_details()
        .then(user_details => {
            this.setState({is_authenticating: false})
            console.log(user_details, this.props.user_details);
        })
        .catch(err => {
          this.setState({is_authenticating: false})  
        });

        // this.props.get_all_license_plans()
        // .then(() => {
        //     console.log('props', this.props);
        // })
        // .catch(err => {
        //     toastr.error('something went wrong')
        // });

        // this.props.get_usage();

        this.props.get_all_stores();
    }

    // componentDidUpdate(e) {
    //   document.documentElement.scrollTop = 0;
    //   document.scrollingElement.scrollTop = 0;
    //   this.refs.mainContent.scrollTop = 0;
    // }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      console.log(prop)
      if (prop.layout === "/home") {
        return (
          <Route path={prop.layout + prop.path} component={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  check_sales_admin = () => {
      var check = false;
      this.props.user_details.role.map(role => {
          if (role.id == 'customer_success_admin' || role.id == 'customer_success_executive') {
              check = true;
          }
      })

      return check;
  }

  set_page_loader = (value, text) => {
    this.setState({page_loader : {show:value, text:text}});
  }

  logout_and_redirect = async() => {
    if(this.props.user_details){
      let resp = await general_fetch({url:'user/logout'})
    }
    window.location.href = global.config.auth_redirect_path
  }

  render() {
      console.log('in', this.state)
      if (this.state.is_authenticating) {
          return null;
      } else if (!this.props.user_details || !this.check_sales_admin()) {
          return <div className="flex_property" style={{alignItems:'center', justifyContent:'center', height:'100vh', fontSize:'20px', flexDirection:'column'}}>
                    <div style={{color:'red'}}>You don't have access to Customer Success Portal !</div>
                    <div style={{color:'#007EF4', textDecoration:'underline', fontSize:'19px', cursor:'pointer', marginTop:'12px'}} onClick={() => this.logout_and_redirect()}>Go to Login Portal</div>
                  </div>
      }

    return (
      <>
        {this.state.page_loader && this.state.page_loader.show?(
            <div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.7',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
                <div style={{position:'absolute',width:'350px',height:'40px',left:'calc(50% - 175px)',top:'calc(50% - 20px)',backgroundColor:'#314456',color:'#ffffff',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}}>
                    <i className = "fa fa-spinner fa-spin"></i> &nbsp; &nbsp; <span>{this.state.page_loader.text}</span>
                </div>
            </div>
        ):''}
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/",
            imgSrc: require("../assets/img/brand/blackLogo.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
              {this.getRoutes(routes)}
              <Route path='/home/store_dashboard/:store_id' render = {({match}) => <StoreDashboard match = {match} set_page_loader = {this.set_page_loader} />}  key={routes.length}  />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    user_details: state.user_details,
    investor_details: state.investor_details,
    license_plans: state.license_plans,
    ...own_props
});

const mapDispatchToProps = dispatch => ({
    get_usage: () => dispatch(get_usage()),
    get_all_stores: () => dispatch(get_all_stores()),
    get_user_details: () => dispatch(get_user_details()),
    get_investor_details: () => dispatch(get_investor_details()),
    get_all_license_plans: () => dispatch(get_all_license_plans()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);