import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {Link} from "react-router-dom";
import general_fetch from "../../utils/fetch.js";

// 3rd party components
import {FormGroup, Modal, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Badge, Card, CardBody, CardTitle, CardHeader, CardFooter, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Table, Container, Row, UncontrolledTooltip, } from "reactstrap";
import Stepper from 'react-stepper-horizontal';
import Moment from 'react-moment';

// core components
import Header from "../../components/Headers/Header.jsx";
import OrderDisplay from "../../components/OrderDisplay";
import UsageDisplay from "../../components/UsageDisplay";

//actions
import {get_all_orders} from '../Orders/actions';
import {get_usage, get_user_details} from '../../actions';
import {get_investment_status, get_all_licenses, update_investment, delete_investment, get_store_account_details, assign_free_credits, get_store_orders} from './actions';

import './style.css';
import Logs from "../../components/Logs/index.jsx";
// import { useAlert } from 'react-alert';


const CreditsModal = ({ show_credits_modal, close_credits_modal, get_account_details, store_id, credits_account, generate_badge }) => {
    const [comment, set_comment] = useState('Extending Trial Period');
    const [credits, set_credits] = useState(0);
    const [credit_type, set_credit_type] = useState('');

    const assign_credits = async () => {
        try {
            var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
            console.log(resp);
            get_account_details();        
            close_credits_modal();
        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        set_comment('Extending Trial Period');
        set_credits(0);
        set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
    }, [ show_credits_modal ]);

    return (
        <Modal className="modal-dialog-centered" size="lg" isOpen={show_credits_modal} toggle={close_credits_modal}>
            <div className="modal-header  bg-gradient-white">
                <h4 className="modal-title" id="exampleModalLabel" style={{margin: 'auto'}}> Assign free credits - {credit_type} </h4>
                <span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={close_credits_modal}>
                  <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body  bg-gradient-secondary" >
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address" >
                          Comments
                        </label>
                        <Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment"  type="text" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address" >
                          Credits
                        </label>
                        <Input value={credits} onChange={e => set_credits(e.target.value)} name="credits" className="form-control-alternative" id="credits_free"  type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" onClick={assign_credits}>Assign Free Credits</Button>
                </div>
            </div>
        </Modal>
    );
}

const TransferCreditsModal = ({ open, toggle, store_id, generate_badge, fetch_store_info }) => {
  const [comment, set_comment] = useState('');
  const [credits, set_credits] = useState(0);
  // const [credit_type, set_credit_type] = useState('');
  // const alert = useAlert()

  const assign_credits = async () => {
    if(comment.trim()){
      try {
          // var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
          try{ 
            var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
            // console.log(resp);
            fetch_store_info()
            toastr.success('Transferred Credits Successfully')

          }
          catch(err){
            // alert.error("Something went Wrong")
            toastr.error('Something went wrong')
          }
          // var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
          // console.log(resp);
          // fetch_store_info()
          toggle();
      } catch(err) {
          console.log(err);
      }
    }else{
      toastr.error('Note is Mandatory')
    }
  }

  useEffect(() => {
      set_comment('');
      set_credits(0);
      // set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
  }, [ open ]);

  return (
      <Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
          <div className="modal-header  bg-gradient-white">
              <h4 className="modal-title" id="exampleModalLabel" style={{margin: 'auto'}}> Transfer free credits </h4>
              <span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
                <span aria-hidden={true}>×</span>
              </button>
          </div>
          <div className="modal-body  bg-gradient-secondary" >
              <div className="pl-lg-4">
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Note
                      </label>
                      <Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment"  type="text" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Credits
                      </label>
                      <Input value={credits} onChange={e => set_credits(Number(e.target.value))} name="credits" className="form-control-alternative" id="credits_free"  type="number" />
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" onClick={assign_credits}>Assign Free Credits</Button>
              </div>
          </div>
      </Modal>
  );
}


const ExtendTrail = ({ open, toggle, store_id, generate_badge, fetch_store_info }) => {
  const [comment, set_comment] = useState('');
  const [credits, set_credits] = useState(0); // days
  // const [credit_type, set_credit_type] = useState('');
  // const alert = useAlert()

  const extend_trail = async () => {
    if(comment.trim()){
      try {
          // var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
          try{ 
            var resp = await general_fetch({url: 'billing/request_and_extend_trial', body:{customer_store_id:store_id, number_of_days:credits, message:comment}});
            // console.log(resp);
            fetch_store_info()
            toastr.success('Trail Extended')

          }
          catch(err){
            // alert.error("Something went Wrong")
            toastr.error('Something went wrong')
          }
          // var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
          // console.log(resp);
          // fetch_store_info()
          toggle();
      } catch(err) {
          console.log(err);
      }
    }else{
      toastr.error('Note is Mandatory')
    }
  }

  useEffect(() => {
      set_comment('');
      set_credits(0);
      // set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
  }, [ open ]);

  return (
      <Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
          <div className="modal-header  bg-gradient-white">
              <h4 className="modal-title" id="exampleModalLabel" style={{margin: 'auto'}}> Extend Trail Date </h4>
              <span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
                <span aria-hidden={true}>×</span>
              </button>
          </div>
          <div className="modal-body  bg-gradient-secondary" >
              <div className="pl-lg-4">
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Note
                      </label>
                      <Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment"  type="text" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Days to Extend
                      </label>
                      <Input value={credits} onChange={e => set_credits(e.target.value ? Number(e.target.value) : 0)} name="credits" className="form-control-alternative" id="credits_free"  type="number" />
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" onClick={extend_trail}>Extend Trail</Button>
              </div>
          </div>
      </Modal>
  );
}

const UpgradePlan = ({ open, toggle, store_id, generate_badge, fetch_store_info }) => {
  // const [comment, set_comment] = useState('');
  const [plan_type, set_plan_type] = useState('business');
  const options = [{plan_type: 'business', displayName: 'Business'}, {plan_type: 'enterprise', displayName: 'Enterprise'}]
  // const [credit_type, set_credit_type] = useState('');
  // const alert = useAlert()
  const upgrade_plan = async () => {
      try {
          // var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
          // var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
          try{ 
            var resp = await general_fetch({url: 'store/upgrade_plan_type', body:{customer_store_id:store_id, plan_type:plan_type}});
            // console.log(resp);
            fetch_store_info()
            toastr.success('Plan Upgrade Successfull')
          }
          catch(err){
            toastr.error('Something went wrong')
            // alert.error("Something went Wrong")
          }
          toggle();
      } catch(err) {
          console.log(err);
      }
  }

  useEffect(() => {
      // set_comment('');
      set_plan_type('business');
      // set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
  }, [ open ]);

  return (
      <Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
          <div className="modal-header  bg-gradient-white">
              <h4 className="modal-title" id="exampleModalLabel" style={{margin: 'auto'}}>  Upgrade Plan </h4>
              <span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
                <span aria-hidden={true}>×</span>
              </button>
          </div>
          <div className="modal-body  bg-gradient-secondary" >
              <div className="pl-lg-4">
                {/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Note
                      </label>
                      <Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment"  type="text" />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Plan Type
                      </label>
                      <Input value={plan_type} onChange={e => set_plan_type(e.target.value)} name="plan_type" className="form-control-alternative" id="plan_type_change"  type="select">
                        {
                          options.map((option, idx) => (
                            <option key={idx} value={option.plan_type}>{option.displayName}</option>
                          ))
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" onClick={upgrade_plan}>Upgrade Plan</Button>
              </div>
          </div>
      </Modal>
  );
}

const ChangeExecutive = ({ open, toggle, store_id, options, fetch_store_info}) => {
  // const [comment, set_comment] = useState('');
  const [customer_success_executive_id, set_customer_success_executive_id] = useState('');
  // const alert = useAlert()
  const change_executive = async () => {
      try {
          // var resp = await assign_free_credits({ comment, credits, customer_store_id: store_id, account_type: credits_account });
          // var resp = await general_fetch({url: 'billing/order/assign_free_credits', body:{customer_store_id:store_id, credits:credits, note:comment}});
          try{ 
            var resp = await general_fetch({url: 'store/assign_customer_success_executive', body:{customer_store_id:store_id, customer_success_executive_id:customer_success_executive_id}});
            // console.log(resp);
            fetch_store_info()
            toastr.success('Customer Success Executive changed Successfully')
          }
          catch(err){
            toastr.error('Something went wrong')
            // alert.error("Something went Wrong")
          }
          toggle();
      } catch(err) {
          console.log(err);
      }
  }

  useEffect(() => {
      // set_comment('');
      // set_customer_success_executive_id('business');
      if(open && options && options.length){
        set_customer_success_executive_id(options[0].id);
      }
      // set_credit_type(credits_account == 'infurnia_credits' ? 'Infurnia Credits' : 'Render Credits');
  }, [ open ]);

  return (
      <Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={toggle}>
          <div className="modal-header  bg-gradient-white">
              <h4 className="modal-title" id="exampleModalLabel" style={{margin: 'auto'}}> Change Executive </h4>
              {/* <span className="pull-right">{generate_badge("Store ID - " + store_id, "info")}</span> */}
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
                <span aria-hidden={true}>×</span>
              </button>
          </div>
          <div className="modal-body  bg-gradient-secondary" >
              <div className="pl-lg-4">
                {/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Note
                      </label>
                      <Input value={comment} onChange={e => set_comment(e.target.value)} name="comment" className="form-control-alternative" id="credits_comment"  type="text" />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-address" >
                        Select Executive
                      </label>
                      <Input value={customer_success_executive_id} onChange={e => set_customer_success_executive_id(e.target.value)} name="customer_success_executive_id" className="form-control-alternative" id="customer_success_executive_id_change"  type="select">
                        {
                          options.map((option, idx) => (
                            <option key={idx} value={option.id}>{option.first_name}</option>
                          ))
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" onClick={change_executive}>Submit</Button>
              </div>
          </div>
      </Modal>
  );
}

const AssignCustomerSuccessExecutive = ({store_info, fetch_store_info}) => {
  const [all_customer_success_executives, set_all_customer_success_executives] = useState([]);
  const [is_csm_open, set_is_csm_open] = useState(false);
  
  const toggle_csm = () => set_is_csm_open(!is_csm_open);

  const fetch_account_details = async() => {
    let account_details = await general_fetch({url: 'user/get_all'});
    if(account_details && account_details.length){
      account_details = account_details.filter(x => !x.depricated && (x.role.includes('customer_success_admin') || x.role.includes('customer_success_executive')))
      set_all_customer_success_executives(account_details)
    }
  }

  useEffect(() => {
    fetch_account_details()
  }, []);

  return (
    <Card className="card-stats mb-4 mb-lg-0">
      <ChangeExecutive open={is_csm_open} toggle={toggle_csm} store_id={store_info ? store_info.id : ''} options={all_customer_success_executives} fetch_store_info={fetch_store_info}/>
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle className="text-uppercase mb-0">
                <h4 className="text-primary" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{"Executive"}</h4>
              </CardTitle>
              <span className="h3 font-weight-bold mt-1 mb-0">{store_info && store_info.customer_success_executive_id ? store_info.customer_success_executive.first_name : "None"}</span>
            </div>
            <Col className="col-auto">
              <div style={{cursor:'pointer'}} onClick={toggle_csm} className="icon icon-shape bg-primary text-white rounded-circle shadow">
                <i className={"fas fa-" + (store_info && store_info.customer_success_executive_id ? "user-edit" : "user-plus")} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    // <div style={{display:'flex', alignItems:'center'}}>
    //   {store_info && store_info.customer_success_executive_id ? 
    //     <span style={{marginRight: '6px'}}>{store_info.customer_success_executive.first_name ? store_info.customer_success_executive.first_name : '' + store_info.customer_success_executive.last_name ? store_info.customer_success_executive.last_name : ''}</span>
    //     :''
    //   }
    //   <div>{store_info && store_info.customer_success_executive_id ? "Change Executive" : "Assign Executive"}</div>
    // </div>
  )
}



class Home extends React.Component {
  
  constructor(props) {
      super(props);
      this.state = {
          orders: null,
          active_tab: 0,
          store_id: this.props.match.params.store_id,
          store_data: {},
          store_info:{},
          license_plans: [],
          license_plan_map: {},
          account_details: {},
          plugin_account_details: {},
          credits_account: null,
          show_credits_modal: false,
          show_transfer_credits_modal: false,
          show_upgrade_plan_modal: false,
          // store_has_unresolved_requests: false,
          store_can_request_extension: false,
          show_trail_extension_modal:false,
      };
  }


  componentWillMount() {
      this.refresh_data();
      this.fetch_store_info()      
  }


  componentDidUpdate(prev_props) {
      // if (prev_props.fetching_store_data !== this.props.fetching_store_data) {
      //     this.refresh_store_info();
      // }
      if(prev_props.match.params.store_id != this.props.match.params.store_id){
        this.fetch_store_info()
      }
  }

  fetch_store_info = async() => {
    let resp = await general_fetch({url:'billing/get_store_info', body:{customer_store_id:this.state.store_id}})
    this.setState({store_info : resp})
    console.log('store info', resp)
    let today = new Date().toISOString()
    let trail_end = new Date(resp.last_trial_date).toISOString()
    this.setState({store_can_request_extension: today <= trail_end})
    // let resp_unresolved_requests = await general_fetch({url: 'billing/get_all_unresolved_trial_requests_of_store', body:{customer_store_id: this.state.store_id}})
  }



  // refresh_store_info = () => {
  //     if (this.props.stores && this.props.stores.length) {
  //         var store_data = this.props.stores.filter(x => x.id == this.state.store_id)[0];
  //         console.log('im in', this.props, store_data)
  //         this.setState({store_data});
  //     }
  // }

  group_by = (arr, key) =>  
      arr.reduce((final, elem) => {
          (final[elem[key]] = final[elem[key]] || []).push(elem);
          return final;
      }, {});


  get_account_details = async () => {
      var store_id = this.props.match.params.store_id;
      var resp = await get_store_account_details(store_id);
      this.setState({ account_details: resp.infurnia_credits_account, plugin_account_details: resp.render_credits_account });
  }

  refresh_data = () => {
      // this.refresh_store_info();
      // var get_orders = get_store_orders(this.props.match.params.store_id);
      
      var get_usage_data = Promise.resolve();
      if (!this.props.usage && !this.props.fetching_usage_data) {
          // get_usage_data = this.props.get_usage()
      }

      // this.get_account_details();

      // this.props.get_all_licenses(this.state.store_id)
      // .then(license_plans => {
      //     console.log('usage => ', license_plans);
      //     var license_plan_map = license_plans.reduce((final, elem) => ({...final, [elem.id]: elem}), {});
      //     this.setState({license_plans, license_plan_map});
          
      //     //handle unassigned licenses
      //     var unassigned_licenses = [];
      //     license_plans.filter(x => (x.subscription_type == 'reassignable' && x.tenor != 'daily' && x.Licenses.length)).map(plan => {
      //         var licenses = plan.Licenses.filter(license => !license.assigned);
      //         if (licenses && licenses.length) {
      //             licenses = this.group_by(licenses, 'days_left');
      //             Object.keys(licenses).map(key => {
      //                 unassigned_licenses.push({plan_id: plan.id, days_left: key, count: licenses[key].length});
      //             })
      //         }
      //     })
      //     // console.log('asdadasd', unassigned_licenses)
      //     this.setState({unassigned_licenses});
      // })
      // .catch(err => toastr.error('Something went wrong'))

      // get_orders
      // .then(orders => {
      //     this.setState({orders});
      // })

  }


  update_inputs = (event) => {
      console.log(this.state);
      this.setState({[event.target.name]: event.target.value})
  }

  generate_stat_card = (text, number, icon) => (
      <div>
          <Card className="card-stats mb-4 mb-lg-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle className="text-uppercase mb-0">
                    <h4 className="text-primary" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{text}</h4>
                  </CardTitle>
                  <span className="h3 font-weight-bold mt-1 mb-0">{number}</span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                    <i className={"fas fa-" + icon} />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
      </div>
  );

  generate_badge = (text, color) => (
      <Badge color={color} className="mb-0" style={{display: "inline-block", marginLeft:"auto"}}><h3 className="mb-0">{text}</h3></Badge>
  )


  toggle_credits_modal = () => this.setState({show_credits_modal : !this.state.show_credits_modal});

  open_credits_modal = credits_account => this.setState({ show_credits_modal: true, credits_account })
  
  close_credits_modal = () => this.setState({ show_credits_modal: false, credits_account: null })

  toggle_transfer_credits_modal = () => this.setState({show_transfer_credits_modal : !this.state.show_transfer_credits_modal});
  
  toggle_upgrade_plan_modal = () => this.setState({show_upgrade_plan_modal : !this.state.show_upgrade_plan_modal});
  
  toggle_extend_trail_modal = () => this.setState({show_trail_extension_modal : !this.state.show_trail_extension_modal});

  // open_transfer_credits_modal = credits_account => this.setState({ show_transfer_credits_modal: true, credits_account })
  
  // close_transfer_credits_modal = () => this.setState({ show_transfer_credits_modal: false, credits_account: null })


  render() {
    const styles = {
      header_row: {
        marginLeft: 0,
        marginRight: 0,
        overflowX: "auto",
      },
      table_header: {
        fontWeight: "bold",
        fontSize: 'small'
      },
      round_status: {
        fontSize: 'small',
        paddingBottom: 0
      },
      nav_pills_rounds: {
        paddingRight: 40,
        marginRight: "0.7rem",
        marginLeft: "0.2rem",
      },
      action_button: {
        margin: 1       
      }
    }; 

    const success_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-success" />
        completed
      </Badge>
    );

    const warning_badge = (
      <Badge color="" className="badge-dot mr-4">
        <i className="bg-warning" />
        pending
      </Badge>
    );




  const unassigned_licenses_table = (
      <Row>
        <h4 className="text-primary mb-2 mt--1" style={{display:"inline-block", margin:"auto"}}>UNATTACHED LICENSES</h4>
        <Table className="align-items-center table-flush text-center" responsive>
          <thead className="thead-light text-center">
            <tr>
              <th scope="col"><span style={styles.table_header}><b>License Plan</b></span><br /></th>
              <th scope="col"><span style={styles.table_header}><b>Days Left</b></span><br /></th>
              <th scope="col"><span style={styles.table_header}><b>Count</b></span> <br /></th>
            </tr>
          </thead>
          <tbody >
              {this.state.unassigned_licenses && this.state.unassigned_licenses.map(row => (
                  <tr>
                      <td>{this.state.license_plan_map[row.plan_id].name}</td>
                      <td>{row.days_left}</td>
                      <td>{row.count}</td>
                  </tr>
              ))}
          </tbody>
        </Table>
      </Row>
    )


    const overview_tab = (
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="6">
              <Card className="card-profile bg-secondary shadow">   
                <CardHeader className="bg-white border-0" >
                  <Row className="align-items-center text-center" style={styles.header_row}>
                      <h3 className="mb-0">Financial Status</h3>
                      {this.state.store_info.premium_customer ? this.generate_badge('premium customer', 'info') : this.generate_badge('trial customer', 'warning')}
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <Row>
                    <div className="col text-center">
                      <br />
                      {this.generate_badge('Payment Type - ' + (this.state.store_info.payment_type), 'info')}
                      <br /> <br />
                      {this.generate_badge('Plan Type - ' + (this.state.store_info.plan_type), 'info')}
                      <br /> <br />
                      {this.generate_badge('Infurnia Credits - ' + (this.state.store_info.balance ? this.state.store_info.balance.toFixed(3) : 0), 'info')}
                    </div>
                  </Row>
                </CardBody>
                <CardFooter>
                    <Row className="text-center">
                        <Col xs="12" className="text-center">
                            <Button onClick={this.toggle_transfer_credits_modal} color="primary">{"Transfer Free Credits"}</Button>
                            <Button onClick={this.toggle_upgrade_plan_modal} color="primary">{"Upgrade Plan"}</Button>
                            {this.state.store_can_request_extension ? <Button onClick={this.toggle_extend_trail_modal} color="primary">{"Extend trail"}</Button> : ''}
                            {/* <Button color="primary" onClick={() => this.open_credits_modal('infurnia_credits')}><span className="fa fa-plus" /> &nbsp; Add Design Credits</Button>
                            <Button color="primary" onClick={() => this.open_credits_modal('render_credits')}><span className="fa fa-plus" /> &nbsp; Add Render Credits</Button> */}
                        </Col>
                    </Row>
                </CardFooter>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0" >
                  <Row className="align-items-center" style={styles.header_row}>
                      <h3 className="mb-0">Billing Address</h3>
                      {this.state.store_data.gst_number ? this.generate_badge("GST - " + this.state.store_data.gst_number, 'info') : this.generate_badge('GST info unavailable', 'warning')}
                  </Row>
                </CardHeader>
                <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-address" >
                              Address (line 1)
                            </label>
                            <Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.line_1 : "N/A"} name="line_1" className="form-control-alternative" id="input-address"  type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-address" >
                              Address (line 2)
                            </label>
                            <Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.line_2 : "N/A"} name="line_2" className="form-control-alternative" id="input-line-2"  type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-city" >
                              City
                            </label>
                            <Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.city : "N/A"} name="city" className="form-control-alternative" id="input-city"  type="text" />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-country">
                              PIN code
                            </label>
                            <Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.state : "N/A"} name="state" className="form-control-alternative" id="input-state"  type="text" />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-country" >
                              Country
                            </label>
                            <Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.country : "N/A"} name="country" className="form-control-alternative"  id="input-country"  type="text" />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-country">
                              PIN code
                            </label>
                            <Input disabled="true" value={this.state.store_info.location ? this.state.store_info.location.pin_code : "N/A"} name="pin_code" className="form-control-alternative" id="input-postal-code"  type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
    )

    const usage_filler = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>No postpaid usage available</b></span><br/> for this store.<br /> &nbsp;</th>
          </tr>
        </thead>
      </Table>
    )

    const orders_filler = (
      <Table className="align-items-center table-flush text-center" responsive>
        <thead className="thead-light text-center">
          <tr>
            <th scope="col"><span style={styles.table_header}><b>No prepaid orders placed</b></span><br/> for this store.<br /> &nbsp;</th>
          </tr>
        </thead>
      </Table>
    )

    const orders_tab = (this.state.orders && this.state.orders.length) ? (<OrderDisplay orders={this.state.orders} show_store_id={false}/>) : orders_filler;
    const usage_tab = (this.state.store_data) ? (<UsageDisplay store_info = {this.state.store_info} usage={this.state.usage} market={this.state.store_data.market} set_page_loader = {this.props.set_page_loader} />) : usage_filler;
    const billing_history_tab = (this.state.store_data) ? (<UsageDisplay store_info = {this.state.store_info} type='billing_history' usage={this.state.usage} market={this.state.store_data.market} set_page_loader = {this.props.set_page_loader} fetch_store_info={this.fetch_store_info} />) : usage_filler;
    const logs = <Logs></Logs>
    const tabs = [overview_tab, billing_history_tab, usage_tab, logs]

    return (
      <>
        <Header />
        {/* Page content */}
        <CreditsModal
            show_credits_modal={this.state.show_credits_modal} 
            close_credits_modal={this.close_credits_modal} 
            get_account_details={this.get_account_details} 
            store_id={this.state.store_data.id} 
            generate_badge={this.generate_badge}
            credits_account={this.state.credits_account}
            fetch_store_info={this.fetch_store_info}
         />
         <TransferCreditsModal 
            open={this.state.show_transfer_credits_modal} 
            toggle={this.toggle_transfer_credits_modal}
            store_id={this.state.store_id} 
            generate_badge={this.generate_badge}
            fetch_store_info={this.fetch_store_info}
          />

          <ExtendTrail
            open={this.state.show_trail_extension_modal} 
            toggle={this.toggle_extend_trail_modal}
            store_id={this.state.store_id} 
            generate_badge={this.generate_badge}
            fetch_store_info={this.fetch_store_info}
          />
          <UpgradePlan 
            open={this.state.show_upgrade_plan_modal} 
            toggle={this.toggle_upgrade_plan_modal}
            store_id={this.state.store_id} 
            generate_badge={this.generate_badge}
            fetch_store_info={this.fetch_store_info}
          />
        <Container className="mt--9" fluid>
            <Row>
                <Col xs="4">{this.generate_stat_card(this.state.store_info.name ? this.state.store_info.name : "Store Name", this.props.match.params.store_id, 'fingerprint')}</Col>
                <Col xs="4">{this.generate_stat_card('Total Users', this.state.store_info && this.state.store_info.all_users ? this.state.store_info.all_users : '0', 'user-astronaut')}</Col>
                {/* <Col xs="3">{this.generate_stat_card('Infurnia Credits', (this.state.store_info && this.state.store_info.balance) ? this.state.store_info.balance.toFixed(3) : 0, 'coins')}</Col> */}
                <Col xs="4">
                  <AssignCustomerSuccessExecutive store_info={this.state.store_info} fetch_store_info={this.fetch_store_info}></AssignCustomerSuccessExecutive>
                </Col>
            </Row>
        </Container>
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-4 bg-gradient-secondary">
                  <Row style={styles.header_row}>
                      <Nav pills style={{paddingBottom: 5, marginTop: 4}}>
                          <NavItem>
                              <NavLink href='#' active={this.state.active_tab == 0} onClick={() => this.setState({active_tab: 0})} ><b>Overview</b> </NavLink>
                          </NavItem>
                          <NavItem>
                              <NavLink href='#' active={this.state.active_tab == 1} onClick={() => this.setState({active_tab: 1})}><b>Billing History </b> </NavLink>
                          </NavItem>
                          <NavItem>
                              <NavLink href='#' active={this.state.active_tab == 2} onClick={() => this.setState({active_tab: 2})}><b>Unbilled Usage</b> </NavLink>
                          </NavItem>
                          {/* <NavItem>
                              <NavLink href='#' active={this.state.active_tab == 3} onClick={() => this.setState({active_tab: 3})}><b>Logs</b> </NavLink>
                          </NavItem> */}
                      </Nav>
                  </Row>
                </CardHeader>
                <CardBody>
                    {tabs[this.state.active_tab]}           
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}


const mapStateToProps = (state, own_props) => ({
    usage: state.usage,
    stores: state.stores,
    user_details: state.user_details,
    fetching_store_data: state.fetching_store_data,
    fetching_usage_data: state.fetching_usage_data,
    ...own_props
})


const mapDispatchToProps = dispatch => ({
    get_usage: () =>  dispatch(get_usage()),
    get_user_details: () =>  dispatch(get_user_details()),
    get_investment_status: () =>  dispatch(get_investment_status()),
    get_all_licenses: store_id =>  dispatch(get_all_licenses(store_id)),
    get_all_orders: () =>  dispatch(get_all_orders()),
    delete_investment: data =>  dispatch(delete_investment(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);